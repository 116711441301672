<template>
  <v-container class="pl-sm-16" fluid>
    <v-data-table
      :headers="headers"
      :items="bids"
      :search="search"
      :loading="api.isLoading"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="teal--text"> Auctions </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogCancel" max-width="500px">
            <v-card>
              <v-card-title>
                Are you sure you want to Cancel this bid?
              </v-card-title>
              <v-card-text class="pa-5">
                <div
                  class="grey lighten-5 rounded-lg pa-5 text-uppercase text-h6"
                >
                  {{ selectedItem.seller }} - {{ selectedItem.mobile_number }}

                  <div class="teal--text">
                    Bid Price {{ selectedItem.bid_price }}
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="dialogCancel = false">
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="cancel"> Yes </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-3"
          ></v-text-field>
        </v-card-title>
      </template>

      <template v-slot:item.cancelled="{ item }">
        <v-btn
          v-if="!item.cancelled"
          small
          color="red"
          text
          @click="
            selectedItem = item;
            dialogCancel = true;
          "
        >
          Cancel Bid
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar v-model="api.isError" :timeout="timeout" text>
      {{ api.error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="teal" text v-bind="attrs" @click="api.isError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  watch: {
    //
  },

  created() {
    this.initialize();
  },
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    dialogCancel: false,
    timeout: 2000,
    search: "",
    headers: [
      { text: "Seller", value: "seller" },
      { text: "Mobile", value: "mobile_number" },
      { text: "Bid Price", value: "bid_price" },
      { text: "Created at", value: "created_at" },
      { text: "Actions", value: "cancelled" },
    ],
    bids: [],
    selectedItem: {
      seller: "",
      mobile_number: "",
      bid_price: 0,
    },
  }),
  mounted() {
    //
  },
  methods: {
    cancel() {
      this.api.url = this.$api.servers.bidding + "/v1/en/admin/bid/cancel";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.initialize();
        this.dialogCancel = false;
      };
      this.api.params = {
        bid_id: this.selectedItem.id,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },
    initialize() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/auction/" +
        this.$route.params.id +
        "/bid/all";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.log(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        console.log(resp);
        this.bids = [];
        resp.forEach((element) => {
          this.bids.push({
            id: element.id,
            seller: element.users.name,
            mobile_number: element.users.mobile_number,
            cancelled: element.cancelled,
            bid_price: element.bid_price,
            created_at: element.created_at,
          });
        });
      };
      this.api.method = "get";
      this.$api.fetch(this.api);
    },
  },
};
</script>

<style>
.name-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.8) 0%,
    rgba(12, 186, 189, 0.8) 100%
  );
  color: white !important;
}

.user-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.5) 0%,
    rgba(12, 186, 189, 0.5) 100%
  );
}

.aution-icon {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 50px !important;
  position: absolute !important;
}
</style>