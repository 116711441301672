<template>
  <v-container class="pl-sm-16" fluid>
    <v-data-table
      :headers="headers"
      :items="views"
      :search="search"
      :loading="api.isLoading"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="teal--text"> Auctions </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-3"
          ></v-text-field>
        </v-card-title>
      </template>
    </v-data-table>
    <v-snackbar v-model="api.isError" :timeout="timeout" text>
      {{ api.error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="teal" text v-bind="attrs" @click="api.isError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  watch: {
    //
  },

  created() {
    this.initialize();
  },
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    timeout: 2000,
    search: "",
    headers: [
      { text: "User", value: "name" },
      { text: "Mobile", value: "mobile_number" },
      
      { text: "Last view at", value: "last_view" },
      { text: "Total views", value: "count" },
    ],
    views: [],
  }),
  mounted() {
    //
  },
  methods: {
    initialize() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/auction/" +
        this.$route.params.id +
        "/view/all";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.log(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        console.log(resp);
        this.views = [];
        resp.forEach((element) => {
          this.views.push({
            id: element.id,
            name: element.users.name,
            mobile_number: element.users.mobile_number,
            last_view: element.last_view,
            count: element.count,
          });
        });
      };
      this.api.method = "get";
      this.$api.fetch(this.api);
    },
  },
};
</script>

<style>
.name-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.8) 0%,
    rgba(12, 186, 189, 0.8) 100%
  );
  color: white !important;
}

.user-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.5) 0%,
    rgba(12, 186, 189, 0.5) 100%
  );
}

.aution-icon {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 50px !important;
  position: absolute !important;
}
</style>